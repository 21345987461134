import video from './maniac_vip_pro.mp4';
import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <video controls height={700} autoPlay>
          <source src={video} type="video/mp4" />
        </video>
      </header>
    </div>
  );
}

export default App;
